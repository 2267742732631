<template>
  <div class="card p-shadow-6">
    <h1>Contactos</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <div style="height: 65vh">
      <DataTable
        :value="contactsList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="username"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem categorias. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div
            class="table-header"
            v-if="$store.state.auth.user.isEsa || can('createContacts')"
          >
            <!-- <Button icon="pi pi-plus" @click="newContact()" /> -->
          </div>
        </template>
        <Column
          field="username"
          filterField="username"
          header="Nome"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          :filterHeaderStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="departement"
          filterField="departement"
          header="Departamento"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="email"
          filterField="email"
          header="Email"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :filterHeaderStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :bodyStyle="{ 'min-width': '20%', 'max-width': '20%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="work_phone"
          header="Telefone Serviço"
          filterField="work_phone"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="personal_phone"
          header="Telefone Pessoal"
          filterField="personal_phone"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #body="slotProps">
            {{
              !slotProps.data.personal_phone
                ? "9** *** ***"
                : slotProps.data.personal_phone
            }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="skills"
          header="Skills"
          filterField="skills"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '30%', 'max-width': '30%' }"
          :filterHeaderStyle="{ 'min-width': '30%', 'max-width': '30%' }"
          :bodyStyle="{ 'min-width': '30%', 'max-width': '30%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              v-if="$store.state.auth.user.isEsa || can('updateContacts')"
              icon="pi pi-pencil"
              v-tooltip.left="'Editar'"
              class="p-button-sm p-button-rounded p-button-success"
              @click="editContact(slotProps.data.employee_id)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="contactModel.showForm"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        (contactModel.id == undefined ? 'Criar' : 'Actualizar') + ' Contacto'
      "
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form
        name="aclCtegoryForm"
        class="p-col-12"
        @submit.prevent="saveContact"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <dropdown
                v-model="contactModel.employeeId"
                :options="employeesList"
                optionLabel="username"
                optionValue="employee_id"
                v-validate="'required'"
                :filter="true"
                :showClear="true"
                :name="'employee_id'"
                :id="'employee_id'"
                :disabled="
                  contactModel.id != undefined || contactModel.id != null
                "
                v-bind:class="[
                  { 'p-invalid': errors.has('employee_id') },
                  'form-control',
                ]"
                @change="employeeChanged"
              />
              <label for="employee_id">Colaborador</label>
            </span>
            <small
              v-if="errors.has('employee_id')"
              class="p-error"
              role="alert"
            >
              Colaborador é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-6 p-mt-2"
            v-if="contactModel.id != undefined && contactModel.id != null"
          >
            <span class="p-float-label">
              <InputText
                v-model="contactModel.email"
                v-bind:class="[
                  { 'p-invalid': errors.has('email') },
                  'form-control',
                ]"
                :name="'email'"
                :id="'email'"
                :disabled="true"
              />
              <label for="email">Email</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <InputText
                v-model="contactModel.workPhone"
                v-bind:class="[
                  { 'p-invalid': errors.has('workPhone') },
                  'form-control',
                ]"
                :name="'workPhone'"
                :id="'workPhone2'"
              />
              <label for="workPhone">Telemóvel Profissional</label>
            </span>
            <small v-if="errors.has('workPhone')" class="p-error" role="alert">
              Telemóvel Profissional ou pessoal é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <InputText
                v-model="contactModel.personalPhone"
                v-bind:class="[
                  { 'p-invalid': errors.has('personalPhone') },
                  'form-control',
                ]"
                :name="'personalPhone'"
                :id="'personalPhone2'"
              />
              <label for="personalPhone">Telemóvel Pessoal</label>
            </span>
            <small
              v-if="errors.has('personalPhone')"
              class="p-error"
              role="alert"
            >
              Telemóvel Profissional ou pessoal é obrigatório
            </small>
          </div>
          <div class="p-col-12">
            <span class="p-float-label p-mb-2" style="font-size: 12px">
              <label for="skills">Skills</label>
            </span>
            <Chips
              :allowDuplicate="false"
              name="skills"
              id="skills2"
              style="width: 100%"
              separator=","
              v-model="contactModel.skills"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveContact"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeContactForm"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import contactsService from "../services/contacts.service";
import employeesService from "../services/employee.service";
import { FilterMatchMode } from "primevue/api/";
export default {
  name: "Contacts",
  data() {
    return {
      loading: true,
      contactsList: [],
      employeesList: [],
      filters: {
        username: { value: null, matchMode: FilterMatchMode.CONTAINS },
        departement: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        work_phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        personal_phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        skills: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      contactModel: { showForm: false },
    };
  },
  async created() {
    this.contactsList = await contactsService.getContacts();
    this.employeesList = await employeesService.getActives();
    this.loading = false;
  },
  methods: {
    closeContactForm() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.contactModel = { showForm: false });
    },
    newContact() {
      this.contactModel = {
        showForm: true,
        skills: null,
      };
      return (this.contactModel.showForm = true);
    },
    editContact(id) {
      this.contactModel.employeeId = id;
      this.employeeChanged({ value: id });
      return (this.contactModel.showForm = true);
    },
    employeeChanged(event) {
      let created = this.contactsList.find(
        (employee) => employee.employee_id == event.value
      );

      if (created) {
        console.log(created);
        this.contactModel = { ...created };
        this.contactModel.id = created.id;
        this.contactModel.workPhone = this.contactModel.work_phone;
        this.contactModel.personalPhone = this.contactModel.personal_phone;
        this.contactModel.employeeId = this.contactModel.employee_id;
        if (this.contactModel.skills != null) {
          this.contactModel.skills = this.contactModel.skills.split(",");
        } else {
          this.contactModel.skills = null;
        }
        this.contactModel.contact = created;
        return;
      }
    },
    saveContact() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        if (this.contactModel.id == undefined || this.contactModel.id == null) {
          return this.saveNewContact();
        }
        return this.updateContact();
      });
    },
    saveNewContact() {
      let bodyParams = {
        employee_id: this.contactModel.employeeId,
      };
      if (this.contactModel.personalPhone != undefined) {
        bodyParams.personal_phone = this.contactModel.personalPhone;
      }
      if (this.contactModel.workPhone != undefined) {
        bodyParams.personal_phone = this.contactModel.workPhone;
      }
      if (this.contactModel.skills.length > 0) {
        bodyParams.skills = this.contactModel.skills.join(",");
      }

      return contactsService.createContact(bodyParams).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao inserir a categoria",
            life: 3000,
          });
        }
        this.contactsList.push(response);
        this.$toast.add({
          severity: "success",
          summary: "Categoria inserida",
          detail: "A categoria foi inserida com sucesso",
          life: 3000,
        });
        return this.closeContactForm();
      });
    },
    updateContact() {
      let bodyParams = {
        employee_id: this.contactModel.employeeId,
      };
      if (this.contactModel.personalPhone != undefined) {
        bodyParams.personal_phone = this.contactModel.personalPhone;
      }
      if (this.contactModel.workPhone != undefined) {
        bodyParams.work_phone = this.contactModel.workPhone;
      }
      if (
        this.contactModel.skills != null &&
        this.contactModel.skills.length > 0
      ) {
        bodyParams.skills = this.contactModel.skills.join(",");
      }
      return contactsService
        .updateContact(this.contactModel.id, bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao actualizar a categoria",
              life: 3000,
            });
          }
          const areYou = (contact) => {
            return contact.id == this.contactModel.id;
          };
          let index = this.contactsList.findIndex(areYou);
          if (index >= 0) {
            this.contactsList.splice(index, 1);
            this.contactsList.push(response);
          }

          this.$toast.add({
            severity: "success",
            summary: "Categoria atualizada",
            detail: "A Categoria foi atualizado com sucesso",
            life: 3000,
          });
          return this.closeContactForm();
        });
    },
  },
};
</script>
<style>
.p-inputtext.p-chips-multiple-container {
  display: block !important;
  width: 100% !important;
}
</style>
